import i18next from 'i18next';

import config from './config';

const enTranslations = {
  search: {
    box: {
      placeholder: 'Search all',
      submitButtonTitle: 'Submit the search query',
      resetButtonTitle: 'Clear the search query',
      tips: '📖 Want to search for documentation? Please visit here: <a href="https://juicefs.com/docs/search" target="_blank">Search Documentation</a>',
    },
    menuLabel: {
      blog: 'Blog',
      customer_story: 'Customer Story',
      event: 'Event',
      solution: 'Solution',
    },
    noResultsText: 'No results have been found for: ',
  },
};

const zhCNTranslations = {
  search: {
    box: {
      placeholder: '搜索所有内容',
      submitButtonTitle: '提交查询',
      resetButtonTitle: '清空查询',
      tips: '📖 想要搜索文档？请访问这里：<a href="https://juicefs.com/docs/zh/search" target="_blank">文档搜索</a>',
    },
    menuLabel: {
      blog: '博客',
      customer_story: '客户案例',
      event: '活动',
      solution: '解决方案',
    },
    noResultsText: '没有搜索结果：',
  },
};

function getLocale() {
  const path = window.location.pathname;
  return path.split('/')[1];
}

// Refer to https://www.i18next.com/how-to/faq#how-should-the-language-codes-be-formatted
function getLanguageCode() {
  switch (getLocale()) {
    case 'en':
      return 'en-US';
    case 'zh-cn':
      return 'zh-CN';
    default:
      return 'en-US';
  }
}

i18next.init({
  debug: config.debug,
  lng: getLanguageCode(),
  resources: {
    en: { translation: enTranslations },
    'zh-CN': { translation: zhCNTranslations },
  },
});

export default {
  getLocale,
};
