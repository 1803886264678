import { configure, hits, menu, pagination, searchBox } from 'instantsearch.js/es/widgets';
import { t } from 'i18next';
import algoliasearch from 'algoliasearch/lite';
import instantsearch from 'instantsearch.js';

import config from './config';
import i18n from './i18n';

function startAlgoliaSearch() {
  console.debug('Start Algolia search component');

  const algoliaClient = algoliasearch(config.algolia.appId, config.algolia.apiKey);

  // Prevent search when query is empty, refer to https://github.com/algolia/react-instantsearch/issues/1111#issuecomment-414239919
  const searchClient = {
    search(requests) {
      const shouldSearch = requests.some(({ params: { query } }) => query !== '');
      if (shouldSearch) {
        return algoliaClient.search(requests);
      }
      return Promise.resolve({
        results: [{ hits: [], nbPages: 0 }],
      });
    },
  };

  const search = instantsearch({
    indexName: config.algolia.indexName,
    searchClient,

    // Hide search content container when query is empty, refer to https://www.algolia.com/doc/guides/building-search-ui/going-further/conditional-display/js/#handling-empty-queries
    searchFunction(helper) {
      const contentContainer = document.querySelector('#content');
      contentContainer.style.display = helper.state.query === '' ? 'none' : '';
      const contentBlank = document.querySelector('#blank');
      contentBlank.style.display = helper.state.query === '' ? '' : 'none';
      helper.search();
    },

    // Sync UI state with URL, refer to https://www.algolia.com/doc/guides/building-search-ui/going-further/routing-urls/js/#rewriting-urls-manually
    routing: {
      stateMapping: {
        stateToRoute(uiState) {
          const indexUiState = uiState[config.algolia.indexName];
          return {
            q: indexUiState.query,
            t: indexUiState.menu && indexUiState.menu.page_type,
            p: indexUiState.page,
          };
        },
        routeToState(routeState) {
          return {
            [config.algolia.indexName]: {
              query: routeState.q,
              menu: {
                page_type: routeState.t,
              },
              page: routeState.p,
            },
          };
        },
      },
    },
  });

  search.addWidgets([
    configure({
      hitsPerPage: 10,
      clickAnalytics: true,
      filters: `language:${i18n.getLocale()}`,
    }),

    searchBox({
      container: "#search-box",
      searchAsYouType: false,
      showSubmit: true,
      showLoadingIndicator: false,
      placeholder: t('search.box.placeholder'),
      templates: {
        submit({ cssClasses }, { html }) {
          return html`
          <button class="${cssClasses.submit}" type="submit" title="${t('search.box.submitButtonTitle')}">
              <svg class="${cssClasses.submitIcon}" width="10" height="10" viewBox="0 0 40 40">
                  <path d="M26.804 29.01c-2.832 2.34-6.465 3.746-10.426 3.746C7.333 32.756 0 25.424 0 16.378 0 7.333 7.333 0 16.378 0c9.046 0 16.378 7.333 16.378 16.378 0 3.96-1.406 7.594-3.746 10.426l10.534 10.534c.607.607.61 1.59-.004 2.202-.61.61-1.597.61-2.202.004L26.804 29.01zm-10.426.627c7.323 0 13.26-5.936 13.26-13.26 0-7.32-5.937-13.257-13.26-13.257C9.056 3.12 3.12 9.056 3.12 16.378c0 7.323 5.936 13.26 13.258 13.26z"></path>
              </svg>
          </button>`;
        },
        reset({ cssClasses }, { html }) {
          return html`
          <button class="${cssClasses.reset}" type="reset" title="${t('search.box.resetButtonTitle')}">
              <svg class="${cssClasses.resetIcon}" viewBox="0 0 20 20" width="10" height="10">
                  <path d="M8.114 10L.944 2.83 0 1.885 1.886 0l.943.943L10 8.113l7.17-7.17.944-.943L20 1.886l-.943.943-7.17 7.17 7.17 7.17.943.944L18.114 20l-.943-.943-7.17-7.17-7.17 7.17-.944.943L0 18.114l.943-.943L8.113 10z"></path>
              </svg>
          </button>`;
        },
      },
    }),

    menu({
      container: '#search-tabs-container',
      attribute: 'page_type',
      transformItems(items) {
        return items.map(item => ({
          ...item,
          label: t(`search.menuLabel.${item.label}`),
        }));
      },
      templates: {
        item(data, { html }) {
          const { label, count } = data;
          return html`<li><a href="#">${label} (${count.toLocaleString()})</a></li>`;
        },
      },
    }),

    hits({
      container: "#search-results-container",
      templates: {
        empty: `<div>${t('search.noResultsText')}<strong>{{ query }}</strong></div>`,
        item(hit, { html, components }) {
          return html`
          <a class="item" href="${hit.url}">
              <div class="title">${components.Highlight({ attribute: 'title', highlightedTagName: 'span', hit })}</div>
              <div class="fragment">${components.Snippet({ attribute: 'pure_body', highlightedTagName: 'span', hit })}</div>
          </a>
        `;
        },
      },
    }),

    pagination({
      container: '#search-paginator',
    }),
  ]);

  const searchBoxTips = document.querySelector('#search-box-tips');
  searchBoxTips.innerHTML = t('search.box.tips');

  search.start();
}

// Only start Algolia in search page
if (window.location.pathname.endsWith('/search')) {
  startAlgoliaSearch();
}
