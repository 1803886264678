import Alpine from 'alpinejs'
import persist from '@alpinejs/persist'
import Clipboard from '@ryangjchandler/alpine-clipboard'
// Google fonts
import '@fontsource-variable/noto-sans-sc';
import '@fontsource-variable/afacad-flux';

import 'prismjs'
import 'prismjs/components/prism-bash'
import 'prismjs/plugins/line-numbers/prism-line-numbers'
import Swiper from 'swiper';
import { Navigation, Autoplay, Pagination } from 'swiper/modules';

// lazyload
import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';

// zoom image
import mediumZoom from 'medium-zoom'

mediumZoom(document.querySelectorAll('.entry img'));

const swiper = new Swiper('.swiper', {
    modules: [ Navigation, Autoplay, Pagination ],
    // Optional parameters
    loop: true,
    // autoplay: {
    //     delay: 3500,
    //     disableOnInteraction: false
    // },
    // If we need pagination
    pagination: {
        el: '.swiper-pagination',
        clickable: true,
    },

    // Navigation arrows
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },

    // And if we need scrollbar
    scrollbar: {
        el: '.swiper-scrollbar',
    },
})

// 初始化 cookie 存储
window.cookieStorage = {
    getItem(key) {
        let cookies = document.cookie.split(";");
        for (let i = 0; i < cookies.length; i++) {
            let cookie = cookies[i].split("=");
            if (key == cookie[0].trim()) {
                return decodeURIComponent(cookie[1]);
            }
        }
        return null;
    },
    setItem(key, value) {
        document.cookie = key+' = '+encodeURIComponent(value)
    }
}

document.addEventListener('alpine:init', () => {
    Alpine.data('userState', () => ({
        state: [],
        async init() {
            try {
                this.state = await fetch('/user_state').then(res => res.json())
            }
            catch(err) {
                console.log('Read user state failed', err)
            }
        },
    }))
})

Alpine.plugin(Clipboard)
Alpine.plugin(persist)

Alpine.start()
