const developmentConfig = {
  debug: true,
  algolia: {
    appId: 'M57LS4K164',
    apiKey: '2cb201d46a984c7888cfd7be5a9de5f9',
    indexName: 'juicefs_cms_dev',  // Refer to ALGOLIA_INDEX_NAME in cms/settings/dev.py
  },
};

const productionConfig = {
  debug: false,
  algolia: {
    appId: 'M57LS4K164',
    apiKey: '2cb201d46a984c7888cfd7be5a9de5f9',
    indexName: 'juicefs_cms_prod',  // Refer to ALGOLIA_INDEX_NAME in cms/settings/production.py
  },
};

// Refer to https://parceljs.org/features/production/#development-branch-removal
const config = process.env.NODE_ENV === 'production' ? productionConfig : developmentConfig;
export default config;
